@import "~antd/dist/antd.less"; // Import Ant Design styles by less entry
@import "Variables";
@import "General";

:root {
    --ifm-font-family-base: 'Work Sans' !important;
    -webkit-font-smoothing: antialiased; /* Pro WebKit prohlížeče jako Chrome a Safari */
    -moz-osx-font-smoothing: grayscale; /* Pro Firefox */
    font-smooth: always; /* Obecný přístup, ale méně podporovaný */
}

body {
    font-family: 'Work Sans', sans-serif;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

input::-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset !important;
}

input::-webkit-autofill:hover,
input::-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px white inset !important;
}

b, .bold {
    font-weight: 600;
}

.sticky-column {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;
}

.sticky-content {
}

.dragIcon {
    position: absolute;
    left: -10px; /* we suppose icon width is 20px (defined by fontSize="small") */
    top: 50%;
    transform: translateY(-50%);
    color: #E1E1E1;
    z-index: 1;
    touch-action: none;
    cursor: var(--cursor, pointer);
    border: none;
    outline: none;
    appearance: none;
    background-color: white;
    border-radius: 50%;
    -webkit-tap-highlight-color: transparent;
}

.descriptionForOnboarding {
    color: #696969
}

.dragIcon:hover {
    color: #666;
}

.dragIcon:focus-visible {
    box-shadow: 0 0px 0px 2px #4c9ffe;
}

tfoot th,
tfoot td {
    background: #fafafa;
}
[data-theme="dark"] tfoot th,
[data-theme="dark"] tfoot td {
    background: #1d1d1d;
}

.waitingOrNotPaidGradientColor {
    background: linear-gradient(to right, rgb(255, 165, 0), rgb(255, 0, 0));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.paidColor {
    color: rgb(0, 128, 0)
}

.hidden-numbers {
    letter-spacing: 0.15em;
}

.lectureDashboardColor {
    color: #C9A0DC
}

.courseDashboardColor {
    color: #AEC6CF
}

.workshopDashboardColor {
    color: #FFB7B2
}

.bundleDashboardColor {
    color: #77DD77
}

.voucherDashboardColor {
    color: #FFD1DC
}

// tour

.grid-with-filters .ant-table-filter-trigger {
    color: #888BBB;
}

.reactour-default-new-lecture-buttons {
    display: flex;
    gap: 15px;
}

.align-end {
    display: flex;
    justify-content: flex-end;
}

.errorBorder {
    border-color: #ff4d4f !important;
}

.loginFormTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    text-align: center;
    margin-bottom: 5px;

    h1 {
        font-size: 28px;
    }
}

.blur-image-container {
    position: relative;
    display: inline-block;
}

.blur-image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: radial-gradient(circle, transparent 60%, rgba(0, 0, 0, 0.3) 80%);
    filter: blur(15px);
    z-index: 1;
}

.blur-image-container img {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 0;
    border-radius: 8px;
}

.mt-5 {
    margin-top: 5px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-10 {
    margin-bottom: 10px;
}

.ml-5 {
    margin-left: 5px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-5 {
    margin-right: 5px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-30 {
    margin-bottom: 30px;
}

small.redtext {
    color: red;
}

.h-full {
    height: 100%;
}

.lecture-reservation {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
}

.create-new-lecture {
    display: flex;
    position: fixed;
    z-index: 1;
    right: 30px;
}

.create-new-lecture-mobile {
    display: flex;
    position: fixed;
    z-index: 1;
    right: 12px;
    bottom: 20px;
}

button.button-is-focused {
    color: @primary-color;
    border-color: @primary-color;
}

button.c-bn {
    .ant-btn !important;
}

button.c_link {
    border: none !important;
}

body {
    font-size: 15px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: @fontFamily;
    background-color: #fafafa;
    letter-spacing: -0.3px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 1.6;
    font-weight: 400;
}

.bnb2 {
    color: #52c41a;
    font-weight: 700;
}

.redWarning {
    color: #E75480;
}

.orangeWarning {
    color: #ff9738
}

.lightGray {
    color: lightgray;
}

.failed {
    color: #FF0000
}

.italic {
    font-style: italic;
}

.page-container-dashboard-wrapper {
    min-height: 80vh;
}

.page-container-dashboard {
    flex: 0 0 auto;
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: 0;
}

.agreement-container {
    margin-top: 15px;
}

.hide {
    display: none;
}

.center-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 0 50px 0 50px;
}

.choose-predefined:extend(.center-container) {
    margin-bottom: 10px;

    button {
        margin-top: 10px;
    }
}

.sendEmailAgainQuestionAction {
    color: @primary-color;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
}

.payment-result-extra-info-error-icon {
    color: @error-color;
}

a.newPlaceLink {
 text-decoration: underline;
}

a.newPlaceLink:hover {
    text-decoration: none;
}

.form-item-helpMessage {
    clear: both;
    min-height: 20px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 13px;
    line-height: 1.5715;
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    margin-top: 5px;
}

.user-dropdown-menu-header {
    display: flex;
    justify-content: flex-start;
    padding: 0 12px 12px 12px;

    .user-dropdown-menu-header--email {
        font-weight: bold;
    }
}

.office-dropdown-menu-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 2rem;
    white-space: nowrap;
    padding: 0 12px;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type="checkbox"],
    input[type="radio"] {
        -webkit-appearance: none;
        -moz-appearance: none;
    }
}

p {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.4px;
    color: @colorDarkText;
}

p.lecture-content__lecture-time {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: -0.33px;
    color: @colorDarkText;
    margin-bottom: 5px;
}

h1 {
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    color: @colorDarkText;
}

.add-with-search-row {
    margin-bottom: 20px;
}

.ant-form-item-explain-error {
    margin-bottom: 5px;
}

.ant-upload.ant-upload-select-picture-card {
    width: 100%;
}

.ant-menu-item.ant-menu-item-selected {
    border-radius: 8px;
}

.ant-menu-title-content {
    font-weight: 500;
}

.radioGroupColumnFlex {
    display: flex !important;
    flex-direction: column !important;
}

.ant-input,
.ant-select,
.ant-input-number-input,
.ant-input-group-addon,
.ant-picker-input > input,
.ant-select-item-option-content {
    font-family: @fontFamily;
    font-size: 14px !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.46px;
}

.ant-input-show-count-suffix {
    font-size: 14px !important;
}

.PhoneInputCountry {
    margin-right: 15px !important;
}

.ant-checkbox {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
    top: 0;
}

.ant-checkbox-wrapper {
    display: inline-flex;
    align-items: center;
}

.highlighted {
    font-weight: 500;
    color: #000;
}

.hidden {
    visibility: hidden;
}

button.app-button__primary:hover {
    background-color: @buttonPrimaryHoverColor;
}

button.app-button__default:hover {
    background-color: @buttonDefaultHoverColor;
    color: @colorDarkText;
}

button.app-button__default:focus {
    color: @primary-color;
    border-color: @primary-color;
}

.plainTable {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;

    .plainTableRoot {
        display: table-row-group;

        .plainTable-row {
            display: table-row;
            line-height: 2em;
            outline: 0;
            vertical-align: middle;
        }
    }
}

.app-button__default[disabled] {
    background-color: @buttonDefaultDisabledBgColor !important;
    color: @buttonDefaultDisabledTextColor !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    font-size: 20px !important;
}

.ant-slider-handle {
    width: 19px;
    height: 19px;
    margin-top: -8px;
}

.ant-select-selection--single .ant-select-selection__rendered {
    display: flex;
    align-items: center;
    flex: initial;
    width: 100%;
}

.ant-form-item .ant-input-textarea-show-count:after {
    margin-bottom: -10px !important;
}

.ant-form-item no-margin {
    margin-bottom: 0;
}

.ant-form-item label {
    font-family: @fontFamily;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: uppercase;
}

.ant-form-item label.ant-checkbox-wrapper {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.4px;
    color: @colorVeryDarkText;
    text-transform: none;
}

.ant-form-item label.ant-radio-wrapper {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.4px;
    color: @colorVeryDarkText;
    text-transform: none;
    border-radius: @border-radius-base !important;
}

.ant-form-item-label > label::after {
    content: "" !important;
}

.ant-layout-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid @defaultLineColor;
    background-color: rgba(240,240,240, 0.9);
}

.ant-alert {
    margin: 10px 0 10px 0;
}

.ant-collapse-header-custom {
    padding: 0;
}

.difficulty-rate {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #fff;

    .difficulty-rate-hearth {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        cursor: pointer;
    }

    .difficulty-rate-hearth--checked {
        .difficulty-rate-hearth;
        width: 30px;
        height: 30px;
        border-radius: 16px;
        border: solid 2px @defaultLineColor;
        background-color: @bgColorLayout;
    }
}

.expired-subscription-overview {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.lecture-content__divider {
    height: 1px;
    background-color: @defaultLineColor;
    margin: 20px 0 14px 0;
}

.ant-drawer-body {
    .lecture-content__operations {
        margin-top: 20px;
    }

    .lecture-content__operations button {
        margin-bottom: 15px;
    }

    .lecture-content__location {
        font-family: @fontFamily;
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: -0.27px;
        color: @colorDarkText;
    }

    p {
        font-family: @fontFamily;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.4px;
    }

    p.lecture-content__difficulty {
        font-size: 14px;
        font-weight: 600;
        color: @colorDarkText;
        line-height: normal;
        margin-bottom: 10px;
    }

    h1 {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: -0.5px;
        color: @colorDarkText;
    }
}

.ul-payment-methods {
    padding-left: 25px;
}

.ul-payment-methods li {
    position: relative;
    padding: 3px 0 2px 3px;
}

.lecture-detail--delete {
    font-stretch: normal;
    font-style: normal;

    p {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;
        letter-spacing: -0.4px;
        color: @colorVeryDarkText;
    }

    p.note {
        font-size: 12px;
        font-weight: normal;
        line-height: 1.5;
        letter-spacing: -0.34px;
        color: @colorDarkText;
    }
}

.lecture-detail--delete > button {
    margin-bottom: 8px;
}

.lecture-detail--administration h1.lecture-detail--delete__heading {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.33px;
    color: @colorDarkText;
}

.lecture-detail--administration {
    font-family: @fontFamily;

    h1 {
        font-size: 30px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.5px;
        color: @colorDarkText;
    }
}

.page-container-middle {
    width: 80vw;
}

.page-container-table {
    padding-top: 15px;
    width: 90vw;
}

.lecture-detail--attendees {
    display: flex;
    flex-direction: column;
    margin-top: 13px;
    margin-bottom: 10px;
    gap: 15px;
}

.ant-popover-inner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 267px;

    .lecture-popover-content {
        font-family: @fontFamily;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;

        .lecture-popover-content-header {
            display: flex;
            justify-content: space-between;

            p.lecture-popover-content__lecture-time {
                font-size: 14px;
                line-height: normal;
                letter-spacing: normal;
                color: @colorNotSoDarkText;
            }
        }

        .lecture-popover-content-header--title {
            font-size: 20px;
            font-weight: 600;
            letter-spacing: -0.57px;
            color: @colorDarkText;
            margin-bottom: 10px;
        }

        .popover__inner-content--buttons {
            display: flex;
            flex-direction: column;
        }

        .popover__inner-content--buttons > button {
            margin-bottom: 8px;
        }
    }
}

.payment-result-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.settings-heading-with-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;

    h2, h3, h4 {
        margin-bottom: 0;
    }
}

h2.settings-heading {
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    color: @colorDarkText;
}

h2.content-box-headline-subsection {
    font-size: 23px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    color: @colorDarkText;
}

h2.content-box-headline-subsection--small {
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    color: @colorDarkText;

    font-size: 21px;
}

.newTermButton {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05), 0px 2px 3px 0px rgba(0, 0, 0, 0.05);
}

h2.lecture-content__heading {
    font-family: @fontFamily;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 40.9999px;
    letter-spacing: normal;
    color: @colorNotSoDarkText;
    text-transform: uppercase;
    margin: 0;
}

.lecture-content__bold-text {
    font-size: 24px;
    font-weight: bold;
    color: @colorDarkText;
}

.withShadow {
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

.settings-terms-container {
    border: 1px solid @defaultLineColor;
    border-radius: @border-radius-base;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 10.5px 11px;
    font-size: 15px;
}

.ant-layout-content {
    padding-top: @zenamuHeaderHeight;
}

.zenamu-header {
    height: @zenamuHeaderHeight;
    background-color: @bgColorHeader;
    color: @colorNotSoDarkText;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 30px 0 30px;
    width: calc(100% - 60px); // because of margins
    padding: 0 20px;
    position: fixed;
    z-index: 1000;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 5%), 0px 2px 3px 0px rgb(0 0 0 / 5%);
}

.zenamu-header-mobile {
    height: @zenamuHeaderHeight;
    background-color: @bgColorHeader;
    color: @colorNotSoDarkText;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    width: 100%;
}

.settings-header-container {
    position: fixed;
    z-index: 10;
    display: flex;
    justify-content: center;
    width: 90%;
    border-top: 1px solid #f0f0f0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 5%), 0px 2px 3px 0px rgb(0 0 0 / 5%);
}

.settings-header-container--sticky {
    .settings-header-container;

    width: 100%;
    position: sticky;
    top: 0;
    border-radius: 8px;
    z-index: 201;
    box-shadow: 0px 2px 10px 0px rgb(102 51 153 / 15%), 0px 2px 3px 0px rgb(102 51 153 / 35%);
}

.lazyLoadedCoverImage {
    display: block;
    width: 100%;
    height: 100%;
    font-family: 'object-fit:cover;object-position:50% 50%';
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
}

.public-info-separator {
    content: "";
    width: 1px;
    height: 20px;
    background-color: @colorHeaderSeparator;
    margin: 0;
    display: inline-block;
}

.app-menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .app-headline-subscription-overview {
        display: flex;
        justify-content: flex-end;
        font-size: small;
    }

    .app-headline--container {
        display: flex;
        flex-direction: column;

        .app-headline-subscription-overview {

            .expired-subscription-overview-mobile-description {
                display: flex;
                font-weight: 500;
                color: #E75480;
            }
        }
    }

    .app-headline {
            font-size: 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: @colorDarkText;
            position: relative; /* Pro pseudoelementy, pokud budou potřeba */
            gap: 10px;

            .studio-logo {
                display: flex;
                align-items: center;
                font-size: 21.9px;
                color: @secondaryColor;
            }

            .studio-logo a {
                .studio-logo;
            }

            .ant-space-compact-block {
                width: auto;
            }

        }

        .app-controls {
            display: flex;
            align-items: center;
        }

        .app-controls a {
            margin-right: 10px;
        }

        .app-settings-menu-headline {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: end;
        }

        .app-headline--right {
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: -0.4px;
            padding: 0;
            display: flex;
            align-items: center;

            .app-headline--right-item {
                color: @primary-color;
                cursor: pointer;
            }

            .app-headline--right-item:hover {
                color: @primaryColorLight;
            }
        }
}

// TIP Tap
.character-count {
    color: #868e96;
    margin-top: 1rem;
}

.public-app-menu {
    .app-menu;
}

.lecture-detail-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.lecture-detail-columns-buttons {
    display: flex;
    flex-direction: column;
    background-color: @bgColorCalendar;
    border: 2px solid @bgColorCalendar;
    border-radius: @border-radius-base;
    padding: 2px 4px 2px 4px;

    button {
        margin-bottom: 10px;
    }
}

.userMenu-profile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 350px;
    padding-left: 12px;
    height: 36px;
    box-sizing: border-box;
    border-radius: 18px;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    color: @colorDarkText;
}

.userProfile__name, .language__name {
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.46px;
    -webkit-font-smoothing: antialiased;
    max-width: 100%;
    display: block;
    // See for more details: https://www.w3schools.com/csSref/css3_pr_text-overflow.asp
    // https://nikitahl.com/pure-css-truncate-text
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 6px;
}

.userProfile__nameContent {
    display: flex;
    align-items: center;
    gap: 5px
}

.userMenu-profile:hover {
    // background-color: @primaryColorLight;
}

.dropdownMenu-select {
    color: @colorDarkText;

    .dropdownMenu-select__name {
        .userProfile__name;
        font-weight: 500;
    }
}

.create-lecture__frequency {
    display: flex;
    flex-direction: row;
    align-items: center;

    .create-lecture__frequency--type {
        width: 75%;
    }

    .create-lecture__frequency--rounds {
        display: flex;
        flex: 1;
        margin-left: 20px;
    }

    .create-lecture__frequency--rounds > .ant-form-item {
        width: 100%;
    }
}

.create-lecture__payment {
    display: flex;
    justify-content: space-between;
}

.cancellationPolicy {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .cancellationPolicy__entity {
    }

    .cancellationPolicy__controls {
        align-items: center;
    }
}

.settings-currencies {
    width: 100%;
    min-height: 300px;
}

.ant-collapse-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
}

.settings-office-config--login-and-logout {
    display: flex;
    width: 100%;
}

.settings-office-config--timeline-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullWidth {
    width: 100%;
}

.lastMonthClientLogin {
    background-color: #FFF1F4;
}

.settings-office-config--section {
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin-bottom: 15px;
}

.settings-office-config--section-penalty {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    gap: 10px;

    .settings-office-config--section-penaltyDescription {
        display: flex;
        justify-content: flex-start;
    }

    .settings-office-config--section-penaltyActions {
        display: flex;
        justify-content: flex-end;
        gap: 7px;
    }
}

.settings-office-config--timeline-item.active {
    background-color: @primaryColorLight;
}

.settings-accepted-payment-methods-section {
    display: flex;
    flex-direction: column;

    .settings-accepted-payment-methods-section--stripe-information {
        margin-bottom: 35px;
        border: 2px solid @defaultLineColor;
        border-radius: @border-radius-base;
        padding: 20px 135px 20px 25px;
        background: url(/assets/images/stripe-grey.svg) right 25px center no-repeat;
        background-size: 80px auto;
    }

    .settings-accepted-payment-methods-section--stripe-information p {
        font-size: 14px;
        text-align: left;
        margin: 0;
    }
}

.entrance-settings-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.license-payment-method--container {
    min-height: 120px;
}

.licenseOrder-summary-total {
    display: flex;
    padding: 5px 0;

    .licenseOrder-summary-total-amount {
        flex: 1 1;
        font-size: 15px;
        text-align: right;
    }
}

.tarif-free {
    color: darkblue !important;
}

.tarif-lite {
    color: #bf3f3f !important;
}

.tarif-professional {
    color: #bf7f3f !important;
}

.tarif-expert {
    color: #4cb24c !important;
}

.tarif-ultimate {
    color: #000 !important;
}

.stepper-container {
    margin-top: 20px;
}

.time-different-timezone {
    font-size: x-small;
    color: @error-color;
}

@media (min-width: 1024px) {
    .loginForm {
        margin: 0 auto;
        max-width: 400px;
        margin-bottom: 5px;
        min-width: 400px;
    }
}

@media (max-width: 767px) {
    .ant-menu-item.ant-menu-item-selected {
        border-radius: 8px;
    }

    h2.settings-heading {
        font-size: 23px;
    }

    h2.content-box-headline-subsection {
        font-size: 20px;
    }

    h2.content-box-headline-subsection--small {
        font-size: 18px;
    }

    .sticky-column {
        position: static; /* Disable sticky positioning on mobile */
    }

    .sticky-content {
        height: auto; /* Adjust height for mobile if needed */
    }

    .newTermButton {
        box-shadow: 0 0 45px rgba(0, 0, 0, 0.7);
    }

    .ant-layout-footer {
        padding: 0.5rem 0 0.5rem 0rem;
    }

    .lecture-detail-columns-buttons {
        padding-top: 15px;
    }

    .lecture-detail-columns-buttons-mobile-drawer {
        .lecture-detail-columns-buttons;

        padding-top: 0;
    }

    .userMenu-profile {
        max-width: 230px;
        padding-left: 0;
    }

    .loginForm {
        width: 80vw;
        min-width: initial;
    }

    .ant-layout-content {
        padding-top: @zenamuHeaderHeight;
    }

    .page-container-middle {
        padding: 0px 7px 0 7px;
        width: 100%;
    }

    .page-container-table {
        padding: 0px 7px 0 7px;
        width: 100%;
    }

    .loginFormTitle {
        padding: 15px 15px 0 15px;

        h1 {
            font-size: 22px;
        }
    }

    .lecture-detail-columns-share {
        display: none;
    }

    .sidebar-left {
        display: none;
    }

    .sidebar-menu__item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
    }

    .sidebar-menu__item-selected p {
        .sidebar-menu__item;
        color: @primary-color;
        font-weight: bold;
    }

    .expired-subscription-overview {
        display: flex;
        flex-direction: column;
        padding: 0 30px 0 30px;
        margin: 10px 0 10px 0;

        .expired-subscription-overview-mobile-button {
            display: flex;
        }
    }

    .zenamu-header {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        position: relative;
        margin: 0 15px 0 15px;
        width: calc(100% - 30px); // because of margins
    }

    .settings-header-container {
        width: 100%;
    }

    .settings-header-container--sticky {
        .settings-header-container;

        width: 100%;
        border-radius: 20px;
    }

    .app-menu {
        .app-headline {
            gap: 5px;

            .studio-logo {
                display: none;
            }

            .app-headline--container {
                .app-headline-subscription-overview {
                    display: none;

                    .expired-subscription-overview-mobile-description {
                        display: flex;
                    }
                }
            }
        }
    }

    .public-app-menu {
        display: flex;
        justify-content: space-between;
        height: @zenamuHeaderHeight;
        background-color: @bgColorHeader;
        box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 5%),
        0px 2px 3px 0px rgb(0 0 0 / 5%);
        padding: 0 20px 0 20px;
        gap: 20px;
    }

    .userMenu-profile .userProfile__name {
        display: none;
    }

}

@primary-color: #663399;@highlight-color: #f5222d;@component-background: #fff;@processing-color: #eb615c;@label-color: #70757b;@background-color-light: #F4F4F8;@font-size-base: 14px;@table-font-size: 14px;@menu-item-font-size: 15px;@form-item-margin-bottom: 10px;@form-vertical-label-padding: 0 0 6px;@checkbox-size: 18px;@checkbox-border-radius: 2px;@select-single-item-height-lg: 41px;@control-padding-horizontal: 12px;@input-height-lg: 41px;@avatar-size-lg: 80px;@avatar-size-base: 30px;@font-size-lg: 16px;@border-radius-base: 8px;@dropdown-edge-child-vertical-padding: 12px;@btn-primary-color: #fff;@btn-primary-shadow: 0 0 0 rgba(0, 0, 0, 0);@btn-height-lg: 41px;@btn-height-base: 38px;@btn-font-size-lg: 16px;@btn-font-weight: 500;@btn-shadow: 0 0 0 rgba(0, 0, 0, 0);@radio-size: 18px;@radio-top: 0;@comment-padding-base: 6px 0;